<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-08-31 15:12:12
 * @LastEditTime: 2022-03-15 17:49:13
 * @Description: 关联配比（新）
 * @Param:
 * @FilePath: \JT_Web_test\src\views\productionManagement\ratioSelection_new.vue
 -->

<template>
    <div class="ratioSelection_new" v-loading="isLoading">
        <div class="head_title_container">
            <div>
                <div class="side1"></div>
                <div class="side2"></div>
                <span class="title">
                    {{ productFormula.mark_number }}
                    {{ productFormula.pouring_name }}
                    {{ productFormula.project_name }}
                    {{ productFormula.place_detail }}
                </span>
                <div class="side2"></div>
                <div class="side1"></div>
            </div>
        </div>
        <div class="ratioSelection_header">
            <ul class="wrapper-box">
                <div class="item">
                    <div>坍落度：</div>
                    <div>{{ productFormula.slump }}</div>
                </div>
                <div class="item">
                    <div>是否出资料：</div>
                    <div>
                        <el-radio-group
                            v-model="productFormula.is_datum"
                            :disabled="editReadOnly"
                            @change="saveProductFormula"
                        >
                            <el-radio :label="true" :disabled="isReadOnly">
                                是
                            </el-radio>
                            <el-radio :label="false" :disabled="isReadOnly">
                                否
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="item">
                    <div>产生抗压试验：</div>
                    <div>
                        <el-radio-group
                            v-model="productFormula.is_testblock"
                            :disabled="editReadOnly"
                            @change="saveProductFormula"
                        >
                            <el-radio :label="true" :disabled="isReadOnly">
                                是
                            </el-radio>
                            <el-radio :label="false" :disabled="isReadOnly">
                                否
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="item">
                    <div>试块组数：</div>
                    <div>
                        <el-input
                            v-model.number="productFormula.block_group"
                            placeholder="请输入内容"
                            :disabled="isReadOnly || editReadOnly"
                            @change="saveProductFormula"
                        ></el-input>
                    </div>
                </div>
                <div class="item">
                    <div>备注：</div>
                    <div>
                        {{ productFormula.remarks }}
                    </div>
                </div>
            </ul>
        </div>
        <div class="ratioSelection_content">
            <div class="base-info">
                <div class="left-box">
                    <div class="machine-list">
                        <div class="carousel_box">
                            <el-carousel height=".92rem" :autoplay="false" indicator-position="outside">
                                <el-carousel-item v-for="(val, i) in mixstationlineData" :key="i">
                                    <div class="item_box">
                                        <div
                                            v-for="(item, index) in val"
                                            :key="`crew_${index}`"
                                            :class="{'machine-item':true,'active': mixstationIndex == item.line_code}"
                                            @click="switchingUnit(item, index)"
                                        >
                                            <div :class="{machine_title: true, machine_save: item.product_formula_number_hjt && !item.sync_status}">
                                                {{ item.line_name }}
                                                <span v-if="item.product_formula_number_hjt && !item.sync_status">已保存</span>
                                                <i class="icon toSave" v-if="item.product_formula_number_hjt && !item.sync_status"></i>
                                                <span v-if="item.sync_status">已发送</span>
                                                <i class="icon toSend" v-if="item.sync_status"></i>
                                                <el-button
                                                    type="primary"
                                                    plain
                                                    @click="sendOut(item)"
                                                    v-if="item.product_formula_number_hjt && !item.sync_status"
                                                >
                                                    发送
                                                </el-button>
                                            </div>
                                            <span>砼配比:{{ item.product_formula_number_hjt ? item.product_formula_number_hjt : '--' }}</span>
                                            <span>砂浆配比:{{ item.product_formula_number_sj ? item.product_formula_number_sj : '--' }}</span>
                                            <div class="active_icon" v-if="mixstationIndex == item.line_code"></div>
                                            <!-- <div class="send-btn active">-->
                                            <!--  <div class="send-btn">-->
                                            <!--       发送-->
                                            <!--   </div>-->
                                        </div>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                    <!-- <template v-for="(item, index) in mixstationlineData">
                        <li :key="`crew_${index}`" :class="{'active': mixstationIndex == index}" @click="switchingUnit(item, index)">
                            <div>
                                <h3>
                                    <span>{{ item.line_name }}</span>
                                    <i class="icon toSave" title="已保存" v-if="item.product_formula_number_hjt && !item.sync_status"></i>
                                    <i class="icon toSend" title="已发送" v-if="item.sync_status"></i>
                                </h3>
                                <el-button
                                    type="primary"
                                    plain
                                    @click="sendOut(item)"
                                    v-if="item.product_formula_number_hjt && !item.sync_status"
                                >
                                    发送
                                </el-button>
                            </div>
                            <p class="concreteNo">
                                砼配比：{{ item.product_formula_number_hjt ? item.product_formula_number_hjt : '--' }}
                            </p>
                            <p class="mortarNo">
                                砂浆配比：{{ item.product_formula_number_sj ? item.product_formula_number_sj : '--' }}
                            </p>
                        </li>
                    </template> -->
                </div>
                <div class="right_box">
                    <div>
                        <div class="title">
                            配比类型：
                        </div>
                        <div class="content">
                            <el-radio-group v-model="formulaTypeVal" @change="mixstationChange">
                                <el-radio
                                    v-for="item in formulaTypeData"
                                    :label="item.value"
                                    :key="item.value"
                                >
                                    {{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                        <div class="content">
                            <div class="btns">
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="proportioningSynchronization"
                                    v-if="isReadOnly==false"
                                    :disabled="editReadOnly"
                                >
                                    配比同步
                                </el-button>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="Choice"
                                    v-if="isReadOnly==false"
                                    :disabled="editReadOnly"
                                >
                                    试验配比
                                </el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="openApprovalComments"
                                    v-if="isShowOpinion"
                                >
                                    意见
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="title">
                            试验配比名称：
                        </div>
                        <div class="content">
                            {{ productFormulaCount.experiment_formula_name }}
                        </div>
                        <div class="title">
                            试验配比编号：
                        </div>
                        <div class="content">
                            {{ productFormulaCount.line_experiment_formula_number }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="params-wrapper">
                <div class="params-item params1">
                    <div class="name">
                        设计容量:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.design_volume_weight }}<i>kg</i>
                    </div>
                </div>
                <div class="params-item params2">
                    <div class="name">
                        实际容重:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.practical_volume_weight }}<i>kg</i>
                    </div>
                </div>
                <div class="params-item params3">
                    <div class="name">
                        坍落度:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.slump }}<i>mm</i>
                    </div>
                </div>
                <div class="params-item params4">
                    <div class="name">
                        水胶比:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.water_binder_ratio }}
                    </div>
                </div>
                <div class="params-item params5">
                    <div class="name">
                        砂率:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.sand_rate }}<i>%</i>
                    </div>
                </div>
                <div class="params-item params6">
                    <div class="name">
                        最大粒径:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.max_particle_size }}<i>mm</i>
                    </div>
                </div>
                <div class="params-item params7">
                    <div class="name">
                        凝胶材料:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.cementing_material }}
                    </div>
                </div>
                <div class="params-item params8">
                    <div class="name">
                        外加剂掺量:
                    </div>
                    <div class="num">
                        {{ productFormulaCount.admixture_dosage }}<i>%</i>
                    </div>
                </div>
            </div>
            <div class="proportioningList">
                <div class="table_body">
                    <table>
                        <thead>
                            <tr>
                                <th style="width:0.7rem;" rowspan="2">
                                    <div>
                                        工控编号
                                    </div>
                                </th>
                                <th style="width:1rem;" rowspan="2">
                                    <div>
                                        工控料仓类型
                                    </div>
                                </th>
                                <th class="line" rowspan="2"></th>
                                <th colspan="6" class="head">
                                    <div>
                                        施工配比设置
                                    </div>
                                </th>
                                <th class="line"></th>
                                <th colspan="8" class="head">
                                    <div>
                                        理论配比管控
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th style="width:1rem;">
                                    <div>
                                        原料分类
                                    </div>
                                </th>
                                <th style="width:1rem;">
                                    <div>
                                        原料子类
                                    </div>
                                </th>
                                <th style="width:1rem;">
                                    <div>
                                        原料规格
                                    </div>
                                </th>
                                <th style="width:1rem;">
                                    <div>
                                        试验配比<br>用量(kg)
                                    </div>
                                </th>
                                <th
                                    style="width:1rem;"
                                    class="constructionConsumption"
                                >
                                    <div>
                                        施工配比<br>用量(kg)
                                    </div>
                                </th>
                                <th style="width:1rem;">
                                    <div>
                                        含水率(%)
                                    </div>
                                </th>
                                <th class="line" rowspan="2"></th>
                                <th>
                                    <div>
                                        原料分类
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        原料子类
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        原料规格
                                    </div>
                                </th>
                                <th style="width:0.8rem;">
                                    <div>
                                        差值
                                    </div>
                                </th>
                                <th class="constructionConsumption">
                                    <div>
                                        施工配比<br>用量(kg)
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        理论配比<br>用量(kg)
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        最小值(kg)
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        最大值(kg)
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item,index) in tableData">
                                <tr :key="index">
                                    <template v-if="item.constructionRatio.length > 0">
                                        <td style="width:0.7rem;" title="工控编号">
                                            {{ item.constructionRatio[0].plcw }}
                                        </td>
                                        <td
                                            title="工控料仓类型"
                                            @click="JumpChange(item.constructionRatio[0])"
                                            style="width:1rem; color: blue; text-decoration: underline;cursor: pointer"
                                        >
                                            {{ item.constructionRatio[0].plcwname }}
                                        </td>
                                        <td class="line"></td>
                                        <td style="width:1rem;" title="原料分类">
                                            {{ item.constructionRatio[0].stock_category_name }}
                                        </td>
                                        <td style="width:1rem;" title="原料子类">
                                            {{ item.constructionRatio[0].material_child_name }}
                                        </td>
                                        <td style="width:1rem;" title="原料规格">
                                            {{ item.constructionRatio[0].material_name }}
                                        </td>
                                        <td style="width:1rem;" title="试验配比用量(kg)">
                                            {{ item.constructionRatio[0].experiment_quantity }}
                                        </td>
                                        <td style="width:1rem;" title="施工配比用量(kg)">
                                            <el-input
                                                min="0"
                                                type="number"
                                                placeholder="请输入"
                                                class="constructionConsumption_input"
                                                v-model="item.constructionRatio[0].product_quantity"
                                                :disabled="isReadOnly || editReadOnly"
                                                @mousewheel.native.prevent
                                                @change="calculation_lin(item)"
                                            >
                                            </el-input>
                                        </td>
                                        <td style="width:1rem;" title="含水率(%)">
                                            <el-input
                                                min="0"
                                                type="number"
                                                placeholder="请输入"
                                                v-model="item.constructionRatio[0].moisture_ratio"
                                                v-if="item.constructionRatio[0].stock_category == 'F3-01-XN-01'"
                                                :disabled="isReadOnly || editReadOnly"
                                                @mousewheel.native.prevent
                                                @change="moistureContentChange(item.constructionRatio[0])"
                                            >
                                            </el-input>
                                        </td>
                                        <td class="line"></td>
                                    </template>
                                    <template v-else>
                                        <td>——</td>
                                        <td>——</td>
                                        <td class="line"></td>
                                        <td>——</td>
                                        <td>——</td>
                                        <td>——</td>
                                        <td>——</td>
                                        <td>——</td>
                                        <td>——</td>
                                        <td class="line"></td>
                                    </template>
                                    <td title="原料分类" :rowspan="rowspanCalculation(item)">
                                        {{ item.stock_category_name }}
                                    </td>
                                    <td title="原料子类" :rowspan="rowspanCalculation(item)">
                                        {{ item.material_child_name }}
                                    </td>
                                    <td title="原料规格" :rowspan="rowspanCalculation(item)">
                                        {{ item.material_name }}
                                    </td>
                                    <td
                                        title="差值"
                                        :rowspan="rowspanCalculation(item)"
                                        :class="[ item.difference_quantity < 0 ? 'red' : 'green' ]"
                                    >
                                        {{ item.difference_quantity }}
                                    </td>
                                    <td
                                        title="施工配比用量（kg）"
                                        class="constructionConsumption"
                                        :rowspan="rowspanCalculation(item)"
                                        :class="{'overrun': item.product_quantity
                                            && item.product_quantity < item.theory_quantity_min
                                            && item.product_quantity > item.theory_quantity_max}"
                                    >
                                        <i class="iconfont iconyujing"></i>
                                        {{ item.product_quantity }}
                                    </td>
                                    <td title="理论配比用量(kg)" :rowspan="rowspanCalculation(item)">
                                        {{ item.theory_quantity }}
                                    </td>
                                    <td title="最小值(kg)" :rowspan="rowspanCalculation(item)">
                                        {{ item.theory_quantity_min }}
                                    </td>
                                    <td title="最大值(kg)" :rowspan="rowspanCalculation(item)">
                                        {{ item.theory_quantity_max }}
                                    </td>
                                </tr>
                                <template v-if="item.constructionRatio.length > 1">
                                    <template v-for="(k, j) in item.constructionRatio">
                                        <tr :key="`tr_${k.plcw}`" v-if="j>0">
                                            <td style="width:0.7rem;" title="工控编号">
                                                {{ k.plcw }}
                                            </td>
                                            <td
                                                title="工控料仓类型"
                                                @click="JumpChange(k)"
                                                style="width:1rem; color: blue; text-decoration: underline;cursor: pointer"
                                            >
                                                {{ k.plcwname }}
                                            </td>
                                            <td class="line"></td>
                                            <td style="width:1rem;" title="原料分类">
                                                {{ k.stock_category_name }}
                                            </td>
                                            <td style="width:1rem;" title="原料子类">
                                                {{ k.material_child_name }}
                                            </td>
                                            <td style="width:1rem;" title="原料规格">
                                                {{ k.material_name }}
                                            </td>
                                            <td style="width:1rem;" title="试验配比用量(kg)">
                                                {{ k.experiment_quantity }}
                                            </td>
                                            <td style="width:1rem;" title="施工配比用量(kg)">
                                                <el-input
                                                    min="0"
                                                    type="number"
                                                    placeholder="请输入"
                                                    class="constructionConsumption_input"
                                                    v-model="k.product_quantity"
                                                    :disabled="isReadOnly || editReadOnly"
                                                    @mousewheel.native.prevent
                                                    @change="calculation_lin(item)"
                                                >
                                                </el-input>
                                            </td>
                                            <td style="width:1rem;" title="含水率(%)">
                                                <el-input
                                                    min="0"
                                                    type="number"
                                                    placeholder="请输入"
                                                    v-model="k.moisture_ratio"
                                                    v-if="k.stock_category == 'F3-01-XN-01'"
                                                    :disabled="isReadOnly || editReadOnly"
                                                    @mousewheel.native.prevent
                                                    @change="moistureContentChange(k)"
                                                >
                                                </el-input>
                                            </td>
                                            <td class="line"></td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="btn_box">
            <div class="btns">
                <el-button
                    type="primary"
                    size="mini"
                    @click="handelDelete"
                    v-if="isReadOnly==false"
                    :disabled="editReadOnly"
                >
                    删除
                </el-button>
                <el-button
                    type="primary"
                    size="mini"
                    :disabled="(!mixstationChecked && formulaTypeChecked !== null) || editReadOnly"
                    v-if="isReadOnly==false"
                    @click="saveLine"
                >
                    保存
                </el-button>
                <el-button size="mini" type="primary" @click="sendOut('','batchSend')">
                    全部发送
                </el-button>
            </div>
        </div>

        <!-- 审批意见 -->
        <el-dialog
            width="30%"
            append-to-body
            :visible.sync="isShowApprovalComments"
            custom-class="opinionListDialog"
        >
            <div class="opinionListContent">
                <h4>审批意见列表</h4>
                <ul>
                    <template v-for="(item, index) in approvalCommentsList">
                        <li :key="`approvalComments_${index}`">
                            <h5>{{ item.line_name }}</h5>
                            <p>{{ item.approval_opinion }}</p>
                            <div class="essentialInfo">
                                <span class="iconfont iconyewuyuan el_left">审批人：{{ item.send_user_name }}</span>
                                <span class="el_right">{{ item.approval_time }}</span>
                            </div>
                            <div
                                class="cornerMarker"
                                :class="{
                                    'pendingTrial': item.approval_status == 0,
                                    'adopt': item.approval_status == 1,
                                    'noAdopt': item.approval_status == 2
                                }"
                            ></div>
                        </li>
                    </template>
                </ul>
            </div>
        </el-dialog>

        <!-- 配比同步 -->
        <el-dialog
            width="6rem"
            append-to-body
            title="配比同步页面"
            :visible.sync="synchronizationShow"
            custom-class="synchronizationDialog"
        >
            <div class="content">
                <div class="checkboxHeader">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                        全选
                    </el-checkbox>
                    <p class="iconfont iconweizhi">
                        当前机组：{{ mixstationName }}
                    </p>
                </div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <template v-for="(item, index) in synchronousUnitData">
                        <el-checkbox :label="item" :key="`synchronousUnit_${index}`">
                            <div class="checkboxLabel">
                                <span>{{ item.sync_line_name }}</span>
                                <p>
                                    允许同步：
                                    <el-tag type="success" v-if="item.is_can_sync">
                                        是
                                    </el-tag>
                                    <el-tag type="success" v-else>
                                        否
                                    </el-tag>
                                </p>
                            </div>
                            <p class="iconfont icontishi">
                                <span v-if="item.source_goods_warning">{{ item.source_goods_warning }}</span>
                                <i v-if="item.source_goods_warning && item.sync_warning_reason">,</i>
                                <span v-if="item.sync_warning_reason">{{ item.sync_warning_reason }}</span>
                            </p>
                        </el-checkbox>
                    </template>
                </el-checkbox-group>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="synchronizationShow = false">
                    关 闭
                </el-button>
                <el-button type="primary" @click="synchronizationConfirm">
                    确 认
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import store from '@/store';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
    name: 'ratio-selection-new',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            isLoading: false,
            // 施工配比基本信息
            productFormula: {
                project_name: '',
                project_detail: '',
                is_datum: false,
                is_testblock: false,
            },

            // 全部生产机组
            mixstationlineData: [],
            // 机组选中项code
            mixstationChecked: null,
            // 机组选中项名称
            mixstationName: '',
            // 机组选中项下标
            mixstationIndex: null,

            // 色块字段取值
            productFormulaCount: {
                design_volume_weight: 0,
                practical_volume_weight: 0,
                slump: 0,
                water_binder_ratio: 0,
                sand_rate: 0,
                max_particle_size: 0,
            },

            // 查看只读（隐藏所有操作按钮）
            isReadOnly: false,
            // 编辑只读（显示所有操作按钮，没有操作权限）
            editReadOnly: false,

            // 审批意见按钮权限
            isShowOpinion: false,
            // 审批意见弹框
            isShowApprovalComments: false,
            // 审批意见数据
            approvalCommentsList: [],

            // 全部配比类型
            formulaTypeData: [
                {
                    label: '砼配比',
                    value: 0,
                },
                {
                    label: '润泵砂浆配比',
                    value: 1,
                },
                {
                    label: '润泵砂浆配比（标号）',
                    value: 2,
                },
            ],
            // 配比类型绑定字段
            formulaTypeVal: 0,
            // 配比类型选中项
            formulaTypeChecked: 0,

            // 列表数据
            tableData: [],

            // 配比同步
            synchronousUnitData: [],
            checkedCities: [],
            synchronizationShow: false,
            checkAll: false,
            isIndeterminate: true,
        };
    },
    computed: {},
    watch: {
        formulaTypeVal(newVal) {
            if (newVal === 0) {
                this.formulaTypeChecked = 0;
            } else if ([1, 2].indexOf(newVal) > -1) {
                this.formulaTypeChecked = 1;
            }
        },
    },
    created() {
        if (this.extr && this.extr.taskNumber) {
            if (this.extr.isReadOnly) {
                this.isReadOnly = this.extr.isReadOnly;
            }
            this.task_number = this.extr.taskNumber;
            // 初始化页面数据
            this.getInit();
        }
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 表头类名
        headerClass({ column }) {
            let className = '';
            if (column.label === 'zw') {
                className = 'cellLine_style';
            }
            return className;
        },
        // 列表特定类名
        cellClass({ column }) {
            let className = '';
            if (column.label === 'zw') {
                className = 'cellLine_style';
            }
            return className;
        },

        // 初始化页面数据
        async getInit() {
            this.Loading = true;
            const res = await this.getInitRequest();
            if (res) {
                if (res.productFormula.is_approval_formula) {
                    this.isShowOpinion = true;
                }
                if (res.productFormula.formula_approval_status === 0) {
                    this.editReadOnly = true;
                }
                this.productFormula = res.productFormula;
                this.productFormula.mark_number = this.productFormula.strength_grade_name + this.productFormula.special_require_name;
                this.mixstationlineData = res.productFormulaLine;
                this.productFormulaLine = res.productFormulaLine;
                let carArr = [];
                const allArr = [];
                res.productFormulaLine.forEach((item, i) => {
                    carArr.push(item);
                    if (carArr.length === 3 || i === res.productFormulaLine.length - 1) {
                        allArr.push(carArr);
                        carArr = [];
                    }
                });
                console.log(allArr, 'allArr');
                this.mixstationlineData = allArr;
                if (this.mixstationlineData.length > 0) {
                    if (!this.mixstationChecked) {
                        this.mixstationChecked = this.mixstationlineData[0].line_code;
                        this.mixstationName = this.mixstationlineData[0].line_name;
                        this.mixstationIndex = 0;
                    }
                    this.mixstationChange();
                }
            }
            this.Loading = false;
        },

        // 切换机组
        switchingUnit(item, index) {
            this.mixstationChecked = item.line_code;
            this.mixstationName = item.line_name;
            this.mixstationIndex = item.line_code;
            this.mixstationChange();
        },

        // 切换列表数据
        mixstationChange() {
            this.tableData = [];
            this.productFormulaCount = {};
            this.mixstationName = this.mixstationlineData.filter(item => item.line_code === this.mixstationChecked)[0].line_name;
            const tableData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });
            if (tableData.length > 0) {
                if (this.formulaTypeChecked === 0) {
                    if (tableData[0].product_formula_hjt) {
                        this.processingData(tableData[0].product_formula_hjt);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                } else if (this.formulaTypeChecked === 1) {
                    if (tableData[0].product_formula_sj) {
                        this.processingData(tableData[0].product_formula_sj);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                }
            } else {
                this.tableData = [];
                this.productFormulaCount = {};
            }
        },

        // 发送
        async sendOut(row, type) {
            const sendLineCode = [];
            if (type === 'batchSend') { // 全部发送
                this.mixstationlineData.forEach(item => {
                    if (item.product_formula_number_hjt && !item.sync_status) {
                        sendLineCode.push(item.line_code);
                    }
                });
            } else {
                sendLineCode.push(row.line_code);
            }

            if (sendLineCode.length === 0) {
                this.$message({
                    message: '请先编辑保存',
                    type: 'warning',
                });
            } else {
                const postRequestData = {
                    task_number: this.extr.taskNumber,
                    send_line_code: sendLineCode,
                };
                const res = await this.sendOutRequest(postRequestData);
                if (res && res.is_control) {
                    let text = '管控提醒';
                    if (res.msg_title) {
                        text = res.msg_title;
                    }
                    this.$alert(`${res.control_msg}`, text, {
                        dangerouslyUseHTMLString: true,
                        type: 'warning',
                        confirmButtonText: '确定',
                    });
                }
                this.$message.success('发送成功');
                this.getInit();
            }
        },

        // 删除生产线配比
        handelDelete() {
            this.$confirm('确认删除当前生产线配比?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.Loading = true;
                const url = '/interfaceApi/production/productformula/del_formula_line/mixp_roportion'
                    + `?taskNumber=${this.task_number}&lineCode=${this.mixstationChecked}`;
                this.$axios.put(url)
                    .then(() => {
                        this.getInit();
                        setTimeout(() => {
                            this.mixstationChange();
                        }, 300);
                        this.Loading = false;
                        this.$message('操作成功');
                    }).catch(error => {
                        this.Loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
        },

        // 选择实验配比
        Choice() {
            const newCode = {
                TableCode: 'choose_experiment_formula',
                QueryString: 'line_code='
                            + this.mixstationChecked + '&formula_type='
                            + this.formulaTypeChecked + '&strength_grade='
                            + this.productFormula.strength_grade + '&station_id='
                            + this.productFormula.station_id,
            };
            if (this.formulaTypeVal === 2) {
                newCode.QueryString = newCode.QueryString + '&is_filter_mark=1';
            }
            this.$toast({
                title: true,
                text: '选择实验配比',
                type: 'eject',
                t_url: 'tableList/index',
                extr: {
                    code: newCode,
                    sureCallback: this.selectCallback,
                },
            });
        },
        // 选择实验配比回调
        async selectCallback(table) {
            const res = await this.selectCallbackRequest(table);
            // 数据处理
            this.processingData(res);
        },

        // 打开审批意见
        async openApprovalComments() {
            const res = await this.openApprovalCommentsRequest();
            if ((res || []).length > 0) {
                this.approvalCommentsList = res;
                this.isShowApprovalComments = true;
            } else {
                this.$message({
                    message: '暂无审批意见！',
                    type: 'warning',
                });
            }
        },

        // 配比同步
        async proportioningSynchronization() {
            this.synchronousUnitData = await this.getSynchronousUnit(this.mixstationChecked);
            this.synchronizationShow = true;
            this.checkAll = false;
            this.checkedCities = [];
        },

        // 全选
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.synchronousUnitData : [];
            this.isIndeterminate = false;
        },
        // 单选
        handleCheckedCitiesChange(value) {
            const checkedCount = value.length;
            this.checkAll = checkedCount === this.synchronousUnitData.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.synchronousUnitData.length;
        },
        // 同步确认
        synchronizationConfirm() {
            if ((this.checkedCities || []).length < 1) {
                this.$message({
                    message: '请先选择需要同步的生产机组！',
                    type: 'warning',
                });
            } else {
                const msgList = [];
                const newDom = this.$createElement;
                this.checkedCities.forEach(item => {
                    let newText = '';
                    if (item.source_goods_warning) {
                        newText += item.source_goods_warning;
                        if (item.source_goods_warning && item.sync_warning_reason) {
                            newText += newText + ',';
                        }
                    } else if (item.sync_warning_reason) {
                        newText += item.sync_warning_reason;
                    }
                    msgList.push(

                        newDom('p', null, [
                            newDom('span', null, `${item.sync_line_name}  `),
                            newDom('i', { style: 'color: #E02020' }, `${newText}。`),
                        ]),
                    );
                });
                this.$msgbox({
                    title: '同步确认',
                    message: newDom('div', null, [
                        ...msgList,
                        newDom('p', null, [
                            newDom('span', null, '是否继续同步？'),
                        ]),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(async () => {
                    const parameter = {
                        product_formula_number: this.productFormulaCount.product_formula_number,
                        syncLineCode: [],
                    };
                    parameter.syncLineCode = this.checkedCities.map(item => item.sync_line_code);
                    const synchronousReturn = await this.synchronousUnit(parameter);
                    if (synchronousReturn) {
                        this.$message({
                            message: '同步成功！',
                            type: 'success',
                        });
                    }
                    this.synchronizationShow = false;
                    this.checkAll = false;
                    this.checkedCities = [];
                    // 初始化页面数据
                    this.getInit();
                });
            }
        },

        // 工控料仓类型弹出
        JumpChange(row) {
            this.$toast(
                {
                    title: true,
                    text: '工控与ERP关系配置',
                    type: 'eject',
                    width: '13rem',
                    height: 'auto',
                    t_url: 'purchaseManage/centralControlConfig',
                    extr: {
                        mixstation_code: this.$takeTokenParameters('StationId'),
                        line_code: this.mixstationChecked,
                        plcw: row.plcw,
                        scene: 'view',
                    },
                }
            );
        },

        // 保存施工配比基本信息
        saveProductFormula() {
            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/productformula/save', this.productFormula)
                .then(() => {
                    this.$message.success('保存成功');
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 保存
        async saveLine() {
            const productFormulaLine = {
                product_formula_number: this.productFormulaCount.product_formula_number || '', // 施工配比编号
                line_experiment_formula_number: this.productFormulaCount.line_experiment_formula_number || '', // 生产线实验配比编号
                experiment_formula_name: this.productFormulaCount.experiment_formula_name,
                task_number: this.extr.taskNumber, // 任务单号
                formula_type: this.formulaTypeChecked, // 配方类型
                line_code: this.mixstationChecked, // 生产线编码
                design_volume_weight: this.productFormulaCount.design_volume_weight || '', // 设计容重
                slump: this.productFormulaCount.slump || '', // 坍落度
                water_binder_ratio: this.productFormulaCount.water_binder_ratio || '', // 水胶比
                sand_rate: this.productFormulaCount.sand_rate || '', // 砂率
                max_particle_size: this.productFormulaCount.max_particle_size || '', // 最大粒径
                practical_volume_weight: this.productFormulaCount.practical_volume_weight, // 实际容重
            };
            // 获取当前选中生产线
            const newLin = this.mixstationlineData.filter(item => {
                return item.line_code === this.mixstationChecked;
            })[0];
            // 生产线名称
            productFormulaLine.line_name = newLin.line_name;
            // 生产拌台
            productFormulaLine.scbt = newLin.scbt;
            // 排序字段
            productFormulaLine.sort_num = newLin.sort_num;

            const productFormulaEntity = [];
            this.tableData.forEach(item => {
                productFormulaEntity.push(...item.constructionRatio);
            });
            productFormulaEntity.forEach((item, index) => {
                item.sort_num = index;
            });

            this.isLoading = true;
            const requestData = {
                productFormulaLine: productFormulaLine,
                productFormulaEntity: productFormulaEntity,
                productFormulaRelExperiments: this.tableData,
            };
            const res = await this.saveLineRequest(requestData);
            if (res) {
                if (res.is_control ) {
                    this.$alert(`${res.control_msg}`, '管控提醒', {
                        dangerouslyUseHTMLString: true,
                        type: 'warning',
                        confirmButtonText: '确定',
                    });
                }
                this.$message.success('保存成功');
                this.getInit();
                store.state.currentOpenList.forEach(item => {
                    item.tableObj.updateTable();
                });
            }
            this.isLoading = false;
        },

        // 计算当前行实验配比和差值
        calculation_lin(data) {
            let nub = 0;
            data.constructionRatio.forEach(item => {
                nub += Number(item.product_quantity) * 10000;
            });
            data.product_quantity = nub / 10000;
            if (data.theory_quantity && data.product_quantity) {
                data.difference_quantity = (Number(data.theory_quantity) * 10000 - Number(data.product_quantity) * 10000) / 10000;
            }
            // 计算基本信息（容重、水胶比、砂率、外加剂掺量、胶凝材料）
            this.calculation_BasicInfoValue();
            this.$forceUpdate();
        },

        // 数据处理
        processingData(res) {
            let newData = [];
            const data = JSON.parse(JSON.stringify(res));
            this.productFormulaCount = JSON.parse(JSON.stringify(data));

            data.productFormulaRelExperimentOutputs.forEach(z => {
                z.constructionRatio = [];
            });

            const correlationRatio = [];
            data.productFormulaEntityOutputs.forEach(k => {
                data.productFormulaRelExperimentOutputs.forEach(item => {
                    if (item.material_code !== ''
                        && k.material_code === item.material_code
                        && (item.material_bg_code !== '' && k.material_bg_code === item.material_bg_code || (item.material_bg_code || '') === '' )
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && item.material_child_name !== ''
                        && k.material_child_name === item.material_child_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && (item.material_child_name === '' || k.material_child_name === '' )
                        && item.stock_category_name !== ''
                        && k.stock_category_name === item.stock_category_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    }
                });
            });
            // eslint-disable-next-line max-len
            const row = data.productFormulaEntityOutputs.filter(item => !correlationRatio.some(ele => ele.material_code === item.material_code && ele.material_child_name === item.material_child_name && ele.stock_category_name === item.stock_category_name));
            // 有工控仓位没有试验配比，试验配比新增一条数据，把工控仓位合并到试验配比数据里
            const unmatchedData = [];

            const result = row.reduce((a, b) => {
                const codeName = `${b.material_code}_${b.material_child_name}_${b.stock_category_name}`;
                if (a[codeName]) {
                    a[codeName].push(b);
                } else {
                    a[codeName] = [b];
                }
                return a;
            }, {});

            for (const item in result) {
                unmatchedData.push({
                    constructionRatio: [...result[item]],
                });
            }

            newData = [...data.productFormulaRelExperimentOutputs, ...unmatchedData];

            newData.forEach(item => {
                if (item.constructionRatio.length > 0) {
                    item.sort = item.constructionRatio[0].plcw;
                } else {
                    item.sort = '999';
                }
            });

            newData.sort(function (a, b) {
                return a.sort - b.sort;
            });

            this.tableData = newData;

            this.calculation_BasicInfoValue();
        },

        // 计算基本信息（容重、水胶比、砂率、外加剂掺量、胶凝材料）
        calculation_BasicInfoValue() {
            let practical_volume_weight = 0; // 容重
            let fineAggregate = 0; // 细骨料
            let coarseAggregate = 0; // 粗骨料
            let water = 0; // 水
            let powder = 0; // 粉料
            let cement = 0; // 水泥
            let admixtures = 0; // 掺合料（分类，字典值205-开头的）
            let powderyExpansiveAgent = 0; // 粉状膨胀剂（规格：外加剂-膨胀剂-粉状膨胀剂）
            let admixture = 0; // 外加剂(排除粉状膨胀剂)

            const rowData = [];
            this.tableData.forEach(item => {
                rowData.push(...item.constructionRatio);
            });
            rowData.forEach(k => {
                practical_volume_weight += Number(Number(k.product_quantity || 0).toFixed(2)) * 100;
                if (k.stock_category_dictcode === '203') {
                    fineAggregate = NP.plus(fineAggregate, k.product_quantity || 0);
                }
                if (k.stock_category_dictcode === '204') {
                    coarseAggregate = NP.plus(coarseAggregate, k.product_quantity || 0);
                }
                if (k.stock_category_dictcode === '207') {
                    water = NP.plus(water, k.product_quantity || 0);
                }
                // eslint-disable-next-line max-len
                if (['202', '205-FHL', '205-GHJ', '205-FMH', '205-GH0', '205-GZF', '205-KZF', '205-LZF', '205-SHF'].indexOf(k.stock_category_dictcode) > -1) {
                    powder = NP.plus(powder, k.product_quantity || 0);
                }
                if (k.stock_category_dictcode === '202') {
                    cement = NP.plus(cement, k.product_quantity || 0);
                }

                if (k.stock_category_dictcode.indexOf('205-') > -1) {
                    admixtures = NP.plus(admixtures, k.product_quantity || 0);
                }

                if (k.stock_category_dictcode === '208') {
                    if (k.material_child_dictcode === '208-PZJ' && k.material_code === '208-PZJ-01') {
                        powderyExpansiveAgent = k.product_quantity;
                    }
                    if (k.material_code !== '208-PZJ-01') {
                        admixture = NP.plus(admixture, k.product_quantity || 0);
                    }
                }
            });
            // 砂率
            if (fineAggregate > 0) {
                // eslint-disable-next-line max-len
                this.productFormulaCount.sand_rate = Number(NP.times(NP.divide(fineAggregate || 0, NP.plus(fineAggregate || 0, coarseAggregate || 0)), 100)).toFixed(2);
            } else {
                this.productFormulaCount.sand_rate = 0;
            }

            // 水胶比
            if (water > 0 && powder > 0) {
                this.productFormulaCount.water_binder_ratio = NP.divide(water, powder).toFixed(2);
            } else {
                this.productFormulaCount.water_binder_ratio = 0;
            }

            // 容重
            this.productFormulaCount.practical_volume_weight = Number(NP.divide(practical_volume_weight, 100)).toFixed(2);

            // 胶凝材料
            this.productFormulaCount.cementing_material = Number(NP.plus(cement, admixtures, powderyExpansiveAgent));

            // 外加剂掺量
            if (this.productFormulaCount.cementing_material) {
                // eslint-disable-next-line max-len
                this.productFormulaCount.admixture_dosage = Number(NP.times(NP.divide(admixture, this.productFormulaCount.cementing_material), 100)).toFixed(2);
            } else if (admixture > 0) {
                this.productFormulaCount.admixture_dosage = 100;
            } else {
                this.productFormulaCount.admixture_dosage = 0;
            }
        },

        // 含水率输入
        moistureContentChange(item) {
            if ((item.moisture_ratio || 0) > 0 && (item.experiment_quantity || 0) > 0) {
                item.product_quantity = NP.times(item.experiment_quantity || 0, NP.plus(1, NP.divide(item.moisture_ratio, 100)));
            }
            const row = [];
            this.tableData.forEach(item => {
                row.push(...item.constructionRatio);
            });
            // 细骨料含水量
            let waters = 0;
            // 水
            const waterList = row.filter(item => item.stock_category === 'F3-01-XN-03');
            // 细骨料
            const fineAggregateList = row.filter(item => item.stock_category === 'F3-01-XN-01');
            if (fineAggregateList.length > 0) {
                fineAggregateList.forEach(z => {
                    waters += NP.times(z.experiment_quantity || 0, NP.divide(z.moisture_ratio || 0, 100));
                });
            }
            if (waterList.length > 0) {
                waterList[0].product_quantity = NP.minus(waterList[0].experiment_quantity || 0, waters);
            }
        },

        // 合并列
        rowspanCalculation(row) {
            let new_rowspan = 1;
            if (row.constructionRatio.length > 1) {
                new_rowspan = row.constructionRatio.length;
            }
            return new_rowspan;
        },

        // 获取上线企业列表
        getInitRequest() {
            const path = `/interfaceApi/production/productformula/formulawithline/${this.extr.taskNumber}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 选择实验配比回调
        selectCallbackRequest(table) {
            // eslint-disable-next-line max-len
            const path = `/interfaceApi/production/experimentformula/choose_experiment_formula/${this.mixstationChecked}/${table.experiment_formula_number}/${table.versions}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 选择原实验配比回调
        additionalCallbackRequest(table) {
            const path = `/interfaceApi/production/standardformula/choose_experiment_formula/${table.line_experiment_formula_number}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 打开审批意见
        openApprovalCommentsRequest() {
            const path = `/interfaceApi/production/productformula/formula_approval/list/${this.extr.taskNumber}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 保存
        saveLineRequest(data) {
            const path = '/interfaceApi/production/productformula/save_line';
            return this.$axios.$post(path, data, { defEx: true });
        },

        // 发送
        sendOutRequest(data) {
            const path = '/interfaceApi/production/productformula/send';
            return this.$axios.$post(path, data, { defEx: true });
        },

        // 获取待同步机组
        getSynchronousUnit(lineCode) {
            return this.$axios.$get(`/interfaceApi/production/productformula/save_line/query_sync_line?line_code=${lineCode}`, { defEx: true });
        },

        // 同步生产线施工配比到其他生产线
        synchronousUnit(data) {
            return this.$axios.$post('/interfaceApi/production/productformula/save_line/sync_new', data, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
.ratioSelection_new
    width 100%
    height 100%
    padding .1rem
    .ul_style
        display flex
        li
            display flex
            font-size 0.14rem
            line-height 0.14rem
            margin-right 0.4rem
            h5
                color #777777
                white-space nowrap
            >span
                color #333333
            >p
                font-weight 600
            >>>.el-radio-group
                display flex
                .el-radio
                    line-height 0.14rem
                    margin-right 0.2rem
                    .el-radio__label
                        font-size 0.14rem
    .wrapper-box
        display flex
        border 1px solid #D7D7D7
        margin .1rem 0
        height .4rem
        .item
            font-size 0.14rem;
            display flex
            width 33%
            &:last-child
                margin-right 0;
            .el-radio
                margin-right 0.05rem;
                white-space nowrap
                >>> .el-radio__label
                    padding-left 0.03rem;
                    font-size 0.14rem
            >div
                width 100%
                margin .02rem
                display flex
                align-items center
                background #F8F8F8
                padding-left .1rem
                &:nth-child(1)
                    color #333
                    justify-content center
                    width 1.3rem
                &:nth-child(2)
                    color #666
                    flex 1
    .ratioSelection_header
        >div
            display flex
            justify-content space-between
            p
                font-size 0.14rem
                line-height 0.24rem
                color #333333
                font-weight 600
            .el-button
                font-size 0.14rem
                height 0.24rem
                padding 0 0.12rem
        ul
            li
                line-height 0.36rem
                >>>.el-radio-group
                    .el-radio
                        line-height 0.36rem
    .ratioSelection_content
        height calc(100% - 0.85rem)
        .base-info
            display flex
            border 1px solid #D7D7D7
            .left-box
                width 8rem
                height 1.3rem
                .title
                    padding 0.15rem 0 0.08rem 0.2rem
                    font-weight bold
                    font-size 0.14rem;
                    color #333333
            .right_box
                flex 1
                height .92rem
                margin .1rem .1rem 0 0
                >div
                    width 100%
                    height 50%
                    border 1px solid #D7D7D7
                    display flex
                    padding .02rem
                    >div
                        background #F8F8F8
                        display flex
                        align-items center
                    .title
                        width 1.5rem
                        padding 0 .05rem
                        margin 0 .02rem 0 .02rem
                        justify-content end
                        font-size .18rem !important
                    .content
                        padding 0 .05rem
                        font-size .16rem !important
                        .btns
                            display flex
                            >>>.el-button--mini
                                padding .04rem !important
                                margin-left .04rem !important
                    &:nth-child(2)
                        border-top none
            .machine-list
                display flex
                padding 0.1rem
                .carousel_box
                    width 100%
                    .item_box
                        display flex
                        .machine-item
                            box-sizing border-box
                            padding 0.07rem
                            margin 0 0.08rem
                            width: 2.5rem;
                            height: 0.92rem;
                            background: #F5F9FF;
                            border: 1px solid #D7D7D7;
                            display flex
                            flex-direction column
                            justify-content space-around
                            position relative
                            cursor: pointer;
                            &.active
                                background: #ECF7FF;
                                box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 0, 0, 0.6);
                                border: 0.02rem solid #1890FF;
                            .active_icon
                                width .25rem
                                height .25rem
                                position absolute
                                right 0
                                bottom -1px
                                background url(./images/active.png) no-repeat
                                background-size 100% 100%
                            .machine_save
                                background #3BA271 !important
                            .machine_title
                                font-weight bold
                                font-size 0.14rem;
                                color #fff !important
                                padding 0 .04rem
                                background #F98552
                                i
                                    display inline-block
                                    width 0.2rem
                                    height 0.2rem
                                    float right
                                    margin-top.05rem
                                    &.toSave
                                        background url(./images/save@2x.png) no-repeat
                                        background-size 100% 100%
                                    &.toSend
                                        background url(./images/send@2x.png) no-repeat
                                        background-size 100% 100%
                                span
                                    float right
                            span
                                font-size 0.12rem
                                &.nullSpan
                                    color #979EA7
                            .send-btn
                                display flex
                                justify-content center
                                align-items center
                                width: 0.4rem;
                                height: 0.2rem;
                                border-radius: 0.02rem;
                                border: 1px solid #1890FF;
                                font-size 0.12rem
                                color #1890FF
                                position absolute
                                top 0.1rem;
                                right 0.1rem;
                                &.active
                                    color #fff;
                                    background #1890FF
        .productionUnit
            padding 0.1rem 0
            display flex
            li
                width 2.2rem
                margin-right 0.17rem
                background #F6F8FC
                border 1px solid #D7D7D7
                padding 0.07rem 0.1rem
                cursor pointer
                &::last-child
                    margin-right 0
                >div
                    display flex
                    justify-content space-between
                    h3
                        color #333333
                        font-size 0.14rem
                        display flex
                        span
                            margin-right 2px
                        i
                            width 0.2rem
                            height 0.2rem
                            &.toSave
                                background url(./images/save@2x.png) no-repeat
                                background-size 100% 100%
                            &.toSend
                                background url(./images/send@2x.png) no-repeat
                                background-size 100% 100%
                    .el-button
                        color 0.12rem
                        line-height 0.18rem
                        padding 0 0.1rem
                p
                    font-size 0.12rem
                    line-height 0.18rem
                    color #666666
                &.active
                    background #ECF7FF
                    border 1px solid #1890FF
                    box-shadow 0px 2px 6px 0px rgba(0, 0, 0, 0.6)
        .proportioningInfo
            padding-bottom 0.1rem
            li
                &:nth-of-type(1)
                &:nth-of-type(2)
                    width 3rem
        .params-wrapper
            margin .1rem 0
            display flex
            justify-content space-between
            border 1px solid #D7D7D7
            padding .02rem
            .params-item
                width: 2.1rem;
                height: 0.35rem;
                font-size .14rem
                display flex
                .name
                    display flex
                    justify-content flex-end
                    align-items center
                    width: .9rem;
                    height: 0.35rem;
                    padding-right .02rem
                    background: rgba(85, 136, 241, 0.1);
                .num
                    padding 0 0.02rem 0 .01rem
                    flex 1
                    display flex
                    justify-content flex-start
                    align-items center
                    color #333333
                    background #F8F8F8
                    font-size 0.18rem
                    margin-left .02rem
                    position relative
                    i
                        color #9B9B9B
                        font-size 0.14rem
                        position absolute
                        right .01rem
                &.params1
                    .name
                        background #F3F6FF
                        color #556FC6
                &.params2
                    .name
                        background #FDF5E3
                        color #FBC95D
                &.params3
                    .name
                        background #EFFAFE
                        color #73C0DE
                    .num
                        background #F8F8F8
                &.params4
                    .name
                        background #F7F3F8
                        color #9B60B4
                &.params5
                    .name
                        background #F7EDE9
                        color #FB8450
                &.params6
                    .name
                        background #F1F9ED
                        color #81BA68
                &.params7
                    .name
                        background #EFF6FF
                        color #5DA1FB
                &.params8
                    .name
                        background #EDF5F1
                        color #3FA474
        .proportioningList
            width 100%
            height calc(100% - 2.65rem)
            overflow-y auto
            .table_body
                table
                    width 100%
                    table-layout inherit
                    word-break break-all
                    border-collapse collapse
                    thead
                        tr
                            &:first-child
                                .line
                                    border-top 1px solid #fff
                            &:last-child
                                th
                                    background #F6F8FC
                    th,td
                        font-size 0.14rem
                        line-height 0.16rem
                        text-align center
                        border 1px solid #D6CEC9
                        padding .02rem
                        height .36rem
                        background #fff !important
                        div
                            width 100%
                            height 100%
                            background #F8F8F8 !important
                            display flex
                            justify-content center
                            align-items center
                        &.line
                            width 4px
                            border-top none
                            border-bottom none
                            background-color #EDF0F6
                    th
                        background #EDF0F5
                        color rgb(2, 39, 130)
                        border 1px solid #D6CEC9
                    td
                        &.constructionRatio
                            border none
                        >i
                            display none
                        >p
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        &.red
                            color red
                        &.green
                            color #30fa3e
                        &.overrun
                            color red
                            i
                                font-size 0.14rem
                                vertical-align top
                                display inline-block
                    .constructionConsumption
                        color #0078FB
                    >>>.constructionConsumption_input
                        .el-input__inner
                            color #0078FB
    .btn_box
        width 100%
        height .8rem
        position absolute
        bottom 0.02rem
        background #fff
        left 0
        display flex
        justify-content center
        align-items center
</style>
<style lang="stylus">
// 意见列表弹出样式
.opinionListDialog
    background rgba(255, 255, 255, 0.3)
    .el-dialog__header
        padding 0
    .el-dialog__body
        padding 0
        overflow hidden
        .opinionListContent
            margin 0.1rem
            background #fff
            padding 0.3rem
            h4
                font-size 0.2rem
                color #333333
                margin-bottom 0.15rem
            ul
                max-height 4rem
                overflow-y auto
                overflow-x hidden
                li
                    border 1px solid #E8E8E8
                    padding 0.15rem
                    position relative
                    margin-bottom 0.15rem
                    &:last-child
                        margin-bottom 0
                    h5
                        font-size 0.16rem
                        line-height 0.16rem
                        color #333333
                        margin-bottom 0.07rem
                    p
                        font-size 0.14rem
                        color #7D7D7D
                        margin-bottom 0.15rem
                    .essentialInfo
                        overflow hidden
                        span
                            color #979EA7
                            font-size 0.12rem
                    .cornerMarker
                        width 0.4rem
                        height 0.4rem
                        position absolute
                        top 0
                        right 0
                        background-repeat no-repeat
                        background-size 100%
                        &.pendingTrial
                            background-image url(./images/corner_mark_no_approval.png)
                        &.adopt
                            background-image url(./images/corner_mark_pass.png)
                        &.noAdopt
                            background-image url(./images/corner_mark_no_pass.png)
// 配比同步弹出
.synchronizationDialog
    border 0.1rem solid rgba(255, 255, 255, 0.3)
    background none
    >div
        background #fff
    .el-dialog__body
        height 5rem
        padding 0.1rem 0
        .content
            height 100%
            .checkboxHeader
                display flex
                justify-content space-between
                margin-bottom 0.1rem
                padding 0 0.2rem
                >p
                    color #326993
                    font-size 0.16rem
            .el-checkbox-group
                padding 0 0.2rem
                overflow-y auto
                height calc(100% - 0.34rem)
                .el-checkbox
                    margin-right 0
                    width 100%
                    display flex
                    padding-left 0.2rem
                    border 1px solid #D7D7D7
                    margin-bottom 0.2rem
                    &:last-child
                        margin-bottom 0
                    &.is-checked
                        border-color #1890FF
                        box-shadow 0px 2px 4px 0px rgba(0, 0, 0, 0.3)
                    .el-checkbox__input
                        line-height 0.44rem
                        .el-checkbox__inner
                            font-size 0.14rem
                    .el-checkbox__label
                        width 100%
                        font-size 0.16rem
                        line-height 0.4rem
                        padding-left 0.1rem
                        .checkboxLabel
                            padding-right 0.2rem
                            display flex
                            justify-content space-between
                            >p
                                color #979EA7
                                font-size 0.14rem
                                .el-tag
                                    height 0.2rem
                                    line-height 0.18rem
                        >p
                            background #FFFBE6
                            color #F7B500
                            font-size 0.14rem
                            line-height 0.3rem
                            margin-left -0.44rem
                            padding-left 0.2rem
                            padding-right 0.1rem
                            width calc(100% + 0.44rem)
                            white-space initial
    .el-dialog__footer
        text-align center
        .el-button
            width 1.6rem
            margin 0 0.2rem
</style>